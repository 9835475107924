<template>
  <v-app>
    <v-main>
      <v-navigation-drawer v-if="$store.state.user!=null" app>
        <v-list v-if="$store.state.user!==null">
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">Михаил Палий</v-list-item-title>
              <v-list-item-subtitle>Администратор</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group color="primary">
            <v-list-item link to="/event/">
              <v-list-item-icon>
                <v-icon>mdi-bell</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>События</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Выйти
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-container fluid>
        <router-view :key="$route.path"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Api from "@/Api";
import State from "@/plugins/state";

export default {
  data: () => ({
    logoutState: new State(),
  }),
  mounted(){
    if (this.$store.state.user==null)
      this.$router.push('/login/');
  },
  methods:{
    logout(){
      this.logoutState.stateLoading();
      Api.service.user.logout(()=>{
        this.logoutState.stateSuccess();
        this.$store.state.user = null;
        this.$router.push('/login/');
      }, ()=>{
        this.logoutState.stateError();
      })
    }
  }
};
</script>
