import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {title: 'Главная'},
    component: () => import('./views/Home.vue')
  },
  {
    path: '/login/',
    meta: {title: 'Войти'},
    component: () => import('./views/Login.vue')
  },
  {
    path: '/event/',
    meta: {title: 'События'},
    component: () => import('./views/Events.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});
router.afterEach((to) => {
  document.title = (typeof to.meta=='object' ? to.meta : to.meta(to)).title
})

export default router
