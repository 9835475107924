
export default class State{
    constructor() {
        this.stateClear();
    }
    isInit = false
    isRequest = false
    isRequestCancel = false
    isLoading = false
    isLoadingSecond = false
    isError = false
    errorMessage = false
    isSuccess = false

    stateClear(){
        this.isInit = false;
        this.isRequest = false;
        this.isRequestCancel = false;
        this.isLoading = false;
        this.isLoadingSecond = false;
        this.isError = false;
        this.errorMessage = null;
        this.isSuccess = false;
    }
    stateInit(){this.stateClear(); this.isInit = true}
    stateRequest(){this.stateClear(); this.isRequest = true}
    stateRequestCancel(){this.stateClear(); this.isRequestCancel = true}
    stateLoading(){this.stateClear(); this.isLoading = true}
    stateLoadingSecond(){this.stateClear(); this.isLoadingSecond = true}
    stateError(errorMessage){this.stateClear(); this.isError = true; this.errorMessage = errorMessage}
    stateSuccess(){this.stateClear(); this.isSuccess = true}

    isModalOpen = false
    modalData = null
    modalData1 = null
    modalOpen(modalData, modalData1){ this.isModalOpen = true; this.modalData = modalData; this.modalData1 = modalData1; }
    modalClose(modalData, modalData1){ this.isModalOpen = false; this.modalData = modalData; this.modalData1 = modalData1; }

}