import Vue from 'vue'
import router from './router'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import './main.scss'
import Vuex from 'vuex'
import moment from "moment";
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.use(Vuex)

moment.locale('ru');

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  vuetify,
  store: new Vuex.Store({
    state: {
      user:null
    }
  })
}).$mount('#app')


