import axios from "axios";

export default class Api {
  static host = '/api'
  static responseComplete = function(response, successCallback, errorCallback){
    try {
        successCallback(response.data);
    }catch (e){
        if (errorCallback!=null) errorCallback(e);
        Api.responseError(e);
    }
    if (typeof response.headers['x-debug-token-link']!='undefined')
      console.log(response.config.method+' '+response.config.url, 'profiler: '+response.headers['x-debug-token-link']);
  }
  static responseError = function(e, errorCallback){
      if (errorCallback!=null) errorCallback(e);
      console.error(e);
  }
  static service = {
    events:{
      list(success, error=null){
        axios.get(Api.host+'/event/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      accept(id, success, error=null){
        axios.get(Api.host+'/event/'+id+'/accept/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    },
    user:{
      login(phone, pass, success, error=null){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("pass", pass);
        axios.post(Api.host+'/user/login/', formData, {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      status(success, error=null){
        axios.get(Api.host+'/user/status/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
      logout(success, error=null){
        axios.get(Api.host+'/user/logout/', {withCredentials: true})
            .then(response => Api.responseComplete(response, success, error))
            .catch(e => Api.responseError(e, error));
      },
    }
  }
}



